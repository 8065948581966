import React from 'react';
import WppPng from '../assets/wpp.png';

function Whatsapp() {
    return (
        <a target='_blank' href='https://api.whatsapp.com/send?phone=553197586903&text=Ol%C3%A1,%20Gostaria%20de%20fazer%20um%20or%C3%A7amento' className='wpp-div'>
            <img src={WppPng} alt="wpp" />
        </a>
    );
}

export default Whatsapp;