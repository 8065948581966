import React, { useState } from 'react';

function Input({ value, onChange, type, name, id, placeholder, maxLength = 250 }) {
    const [tocado, setTocado] = useState(false);

    const handleInputTocado = () => {
        setTocado(true);
    };

    const isNomeValido = value.length >= 10;

    const inputClassName = !isNomeValido && !tocado || isNomeValido && tocado ? '' : 'input-invalid';

    return (
        type === 'textarea' ? (
            <textarea
                minLength={50}
                onFocus={handleInputTocado}
                value={value}
                onChange={onChange}
                name={name}
                id={id}
                className={inputClassName}
                placeholder={placeholder}
                required
            />
        ) : (
            <input
                minLength={10}
                maxLength={maxLength}
                required
                onFocus={handleInputTocado}
                value={value}
                onChange={onChange}
                type={type}
                name={name}
                id={id}
                className={inputClassName}
                placeholder={placeholder}
            />
        )
    );
}

export default Input;
