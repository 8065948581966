import React, { useEffect, useState } from 'react';

function Cookie() {
    const [aparecerCookie, setAparecerCookie] = useState(false)

    useEffect(() => {
        checkCookie()
    })


    const setCookie = (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        setAparecerCookie(false)
    }

    const getCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const checkCookie = () => {
        let user = getCookie("accepted_terms_cookie");
        if (user != "") {
            setAparecerCookie(false)
        } else {
            setAparecerCookie(true)
        }
    }

    return (
        <div id='cookie' className='cookie' style={{ display: aparecerCookie ? 'flex' : 'none' }}>
            <div className="cookie-text">
                <h5>Usamos cookies</h5>
                <p>Usamos cookies para garantir que nossa página funcione corretamente e que você tenha a melhor experiência
                    possível. Ao aceitar, você nos permite definir os cookies no seu dispositivo.</p>
            </div>
            <div className="cookie-btn">
                <button onClick={() => setCookie("accepted_terms_cookie", "Decline", 1)} className="cookie-btn-nao">Rejeitar</button>
                <button onClick={() => setCookie("accepted_terms_cookie", "Accept", 365)} className="cookie-btn-sim">OK</button>
            </div>
        </div>
    )
}

export default Cookie