import React, { useState } from 'react';
import Cookie from './components/Cookie';
import Whatsapp from './components/Whatsapp';
import Loader from './components/loader/Loader';
import axios from 'axios';
import Input from './components/Input';
import Alerta from './components/alert/Alert';

function App() {
    const urlBase = 'https://api.gsctech.com.br';
    const [loader, setLoader] = useState(false);

    const [showAlert, setshowAlert] = useState(false);
    const [message, setMessage] = useState('');

    const [ocamentoData, setOcamentoData] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
        ehAjuda: false
    });
    const [ajudaData, setAjudaData] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
        ehAjuda: true
    });

    const handleChangeOrcamento = (e) => {
        setOcamentoData({ ...ocamentoData, [e.target.name]: e.target.value });
    };

    const handleChangeAjuda = (e) => {
        setAjudaData({ ...ajudaData, [e.target.name]: e.target.value });
    };

    const goToForm = (id) => {
        var div = document.getElementById(id);
        if (div) {
            div.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleSubmitOrcamento = async (ajuda) => {
        setLoader(true);
        let data = ajuda ? ajudaData : ocamentoData;
        try {
            const response = await axios.post(`Enviar`, data, {
                headers: {
                    'Authorization': `Bearer ba623c05-0a2e-36cf-b6ae-4836e5cef31k`
                }
            });
            setMessage(response.data);
            setshowAlert(true)
            setLoader(false);
        } catch (error) {
            setshowAlert(true)
            setMessage("Erro ao enviar o email. Tente novamente mais tarde");
            setLoader(false);
        }
    };


    return (
        <main>
            <Alerta
                close={() => setshowAlert(false)}
                msg={message}
                show={showAlert}
            />
            <Loader
                show={loader} />
            <Cookie />
            <Whatsapp />
            <header className="nav">
                <h2>
                    {'< GSC TECH />'}
                </h2>
            </header>
            <section className="banner-container">
                <div className="banner-shadow">
                    <div className="banner">
                        <span>Automatize seus</span>
                        <span>processos com</span>
                        <span>Gsc Tech!</span>
                        <button onClick={() => goToForm('orcamento')}>Entrar em contato</button>
                    </div>
                </div>
            </section>
            <section id='orcamento' className="orcamento-container">
                <div className="orcamento-box">
                    <h1>Faça seu orçamento</h1>
                    <div className="orcamento">
                        <span>Seu tempo vale ouro, e a automação pode libertá-lo! Simplifique seus processos manuais e ganhe
                            eficiência. Deixe que a tecnologia assuma o trabalho pesado enquanto você se concentra no que
                            realmente importa. Descubra como automatizar suas tarefas pode transformar sua produtividade.
                            Solicite um orçamento agora e dê o primeiro passo para uma operação mais ágil e inteligente.</span>
                        <form onSubmit={e => { e.preventDefault(); handleSubmitOrcamento(false) }}>
                            <Input
                                value={ocamentoData.nome}
                                onChange={handleChangeOrcamento}
                                type="text"
                                name="nome"
                                id="name"
                                placeholder="Nome *" />
                            <Input
                                value={ocamentoData.email}
                                onChange={handleChangeOrcamento}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email *" />
                            <Input
                                value={ocamentoData.telefone}
                                onChange={e => setOcamentoData({ ...ocamentoData, ["telefone"]: e.target.value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3") })}
                                type="tel"
                                name="phone"
                                id="phone"
                                maxLength={15}
                                placeholder="Telefone *"
                            />
                            <Input
                                type="textarea"
                                value={ocamentoData.mensagem}
                                onChange={handleChangeOrcamento}
                                name="mensagem"
                                id="description"
                                placeholder="Sobre o projeto *"
                            />
                            <div>
                                <button type='submit' className="btn">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="orcamento-container">
                <div className="orcamento-box">
                    <div className="duvida-box">
                        <div>
                            <h1 className="azul">Dúvidas frequentes</h1>
                            <span className="desc">Encontre respostas para as perguntas mais comuns sobre nossos serviços de
                                automação e
                                desenvolvimento.</span>
                        </div>
                        <br /><br /><br />
                        <h3 className="azul s-1">Como a automação pode beneficiar meu negócio?</h3>
                        <span className="desc"> A automação de processos pode aumentar a eficiência e otimizar o tempo gasto em
                            tarefas
                            repetitivas, permitindo que sua equipe se concentre em atividades mais estratégicas.</span>
                        <br /><br />
                        <h3 className="azul s-1">Quais tecnologias a GSC TECH utiliza em seus serviços?</h3>
                        <span className="desc">Utilizamos C#, Python e tecnologias modernas para fornecer soluções inovadoras e
                            eficientes
                            para nossos clientes.</span>
                        <br /><br />
                        <h3 className="azul s-1">
                            Como a inteligência artificial impulsiona a inovação na manufatura?</h3>
                        <span className="desc">A incorporação de inteligência artificial na indústria de manufatura pode
                            revolucionar a forma como as empresas operam, possibilitando a previsão de falhas em equipamentos,
                            otimização de cadeias de suprimentos e personalização em larga escala de produtos.</span>
                    </div>
                </div>
            </section>
            <section className="contato-container">
                <div className="contato-box">
                    <h1>Entre em contato conosco</h1>
                    <h3>Fale conosco:</h3>
                    <div className="orcamento">
                        <span style={{ textAlign: 'center' }}>Tem alguma pergunta? Deixe uma mensagem e entraremos em contato o mais
                            breve possível.</span>
                        <form onSubmit={e => { e.preventDefault(); handleSubmitOrcamento(true) }}>
                            <span>Nome *</span>
                            <Input
                                value={ajudaData.nome}
                                onChange={handleChangeAjuda}
                                type="text"
                                name="nome"
                                id="name"
                                placeholder="" />
                            <span>Email *</span>
                            <Input
                                value={ajudaData.email}
                                onChange={handleChangeAjuda}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="" />
                            <span>Telefone *</span>
                            <Input
                                value={ajudaData.telefone}
                                onChange={e => setAjudaData({ ...ajudaData, ["telefone"]: e.target.value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3") })}
                                type="tel"
                                name="phone"
                                id="phone"
                                maxLength={15}
                                placeholder=""
                            />
                            <span>Mensagem *</span>
                            <Input
                                type="textarea"
                                value={ajudaData.mensagem}
                                onChange={handleChangeAjuda}
                                name="mensagem"
                                id="description"
                                placeholder=""
                            />
                            <div>
                                <button type='submit' className="btn">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="orcamento-container">
                <div className="orcamento-box">
                    <div className="valor-container">
                        <div className="valor-box">
                            <h2>SOFTWARE</h2>
                            <br />
                            <h4>A PARTIR DE</h4>
                            <h1 className="promo2">De R$ 899,99</h1>
                            <h1 className="azul" style={{ marginTop: '0px', margin: '1rem' }}>Por R$ 699,99</h1>
                            <button className="btn" onClick={() => goToForm('orcamento')}>CONTRATAR</button>
                            <br /><br /><br />
                            <ul>
                                <li>Automação de sistemas ✅</li>
                                <li>Automação de serviço manual✅</li>
                                <li>Aplicativo DeskTop ✅</li>
                            </ul>
                        </div>
                        <div className="separador">
                            <hr />
                        </div>
                        <div className="valor-box">
                            <h2>SITE</h2>
                            <br />
                            <h4>A PARTIR DE</h4>
                            <h1 className="promo2"> R$ 1999,99</h1>
                            <h1 className="azul" style={{ marginTop: '0px', margin: '1rem' }}>Por R$ 1499,99</h1>
                            <button className="btn" onClick={() => goToForm('orcamento')}>CONTRATAR</button>
                            <br /><br /><br />
                            <ul>
                                <li>Apontamento do DNS do Domínio ✅</li>
                                <li>Configuração do Servidor ✅</li>
                                <li>Site com Front-End e Back-End ✅</li>
                                <li>Banco de dados(MySql,SqlServer)✅</li>
                            </ul>
                        </div>
                    </div>
                    <small className="azul" style={{ textAlign: 'center' }}>O valor do projeto pode variar conforme suas especificações. Entre em contato para um orçamento.</small>
                    <div className="footer-container">
                        <div className="footer">
                            <br />
                            <h5>Copyright &copy; 2024 Gsc Tech LTDA</h5>
                            <p>CNPJ n.º 47.274.536/0001-69 / R. Luiz Rodrigues Araujo, nº 85, Céu Azul, Belo Horizonte/MG - CEP
                                31580-540</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default App;