import React from 'react';
import './loader.css'; // Estilo CSS para o Loader

const Loader = ({show}) => {
    return (
        <div className="loader-container" style={{display:show?'block':'none'}}>
            <div className="loader"></div>
        </div>
    );
}

export default Loader;