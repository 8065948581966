import React from 'react';
import './alert.css'

function Alerta({ msg, show, close }) {
    return (
        <div id="alertModal" style={{ display: show ? 'block' : 'none' }} class="modal">
            <span onClick={close} class="close">&times;</span>
            <div class="modal-content">
                <p style={{ padding: '20px' }}>{msg}</p>
            </div>
        </div>
    );
}

export default Alerta;